import {
  getPiece, isOwnPiece, offBoard,
} from './tools';

import inspectMove from './inspectMove';

function addKingMove(position, square, moveslist = []) {
  try {
    const list = [...moveslist];
    const p = position;
    const s = square;
    const piece = getPiece(p, s);
    if (piece !== 6 && piece !== 12) return false;
    const moveRange = [1, 11, 10, 9, -1, -11, -10, -9];
    for (let i = 0; i < moveRange.length; i += 1) {
      const t = s + moveRange[i];
      if (list.indexOf(t) === -1
        && inspectMove(p, s, t) !== false) return t;
    }
    if (list.indexOf(77) === -1 && piece === 6 && s === 75 && inspectMove(p, s, 77)) return 77;
    if (list.indexOf(73) === -1 && piece === 6 && s === 75 && inspectMove(p, s, 73)) return 73;
    if (list.indexOf(7) === -1 && piece === 12 && s === 5 && inspectMove(p, s, 7)) return 7;
    if (list.indexOf(3) === -1 && piece === 12 && s === 5 && inspectMove(p, s, 3)) return 3;
    return false;
  } catch (err) {
    return err;
  }
}

function addKnightMove(position, square, moveslist = []) {
  try {
    const list = [...moveslist];
    const p = position;
    const s = square;
    const piece = getPiece(p, s);
    if (piece !== 2 && piece !== 8) return false;
    const moveRange = [-8, -12, -19, -21, 8, 12, 19, 21];
    for (let i = 0; i < moveRange.length; i += 1) {
      const t = s + moveRange[i];
      if (list.indexOf(t) === -1
        && inspectMove(p, s, t) !== false) return t;
    }
    return false;
  } catch (err) {
    return err;
  }
}

function addPawnMove(position, square, moveslist = []) {
  try {
    const list = [...moveslist];
    const p = position;
    const s = square;
    const piece = getPiece(p, s);
    if (piece !== 1 && piece !== 7) return false;
    const moveRange = piece === 1 ? [-11, -10, -20, -9] : [11, 10, 20, 9];
    for (let i = 0; i < moveRange.length; i += 1) {
      const t = s + moveRange[i];
      if (list.indexOf(t) === -1
        && inspectMove(p, s, t) !== false) return t;
    }
    // console.log('pawnmove: false');
    return false;
  } catch (err) {
    return err;
  }
}

function addLineMove(position, square, moveslist = []) {
  try {
    const list = [...moveslist];
    const p = position;
    const s = square;
    const piece = getPiece(p, s);
    let dif;
    switch (true) {
      case piece === 3 || piece === 9:
        dif = [-11, -9, 9, 11];
        break;
      case piece === 4 || piece === 10:
        dif = [-1, 1, -10, 10];
        break;
      case piece === 5 || piece === 11:
        dif = [-1, 1, -10, 10, -11, -9, 9, 11];
        break;
      default: return false;
    }

    for (let i = 0; i < dif.length; i += 1) {
      for (let t = s + dif[i]; offBoard(p, t) === false; t += dif[i]) {
        if (list.indexOf(t) === -1
          && inspectMove(p, s, t) !== false) return t;
      }
    }
    return false;
  } catch (err) {
    return err;
  }
}

// function addPawnMove(position, square, moveslist = []) {
//   // en passant not finished !!!
//   try {
//     const list = [...moveslist];
//     const p = position;
//     const s = square;
//     const piece = getPiece(p, s);
//     let target;
//     switch (true) {
//       // white pawn on 2 row moves 2 squares
//       case list.indexOf(s - 20) === -1 && s > 60 && s < 69 && piece === 1
//       && getPiece(p, s - 10) === 0 && getPiece(p, s - 20) === 0
//       && inspectMove(p, s, s - 20):
//         target = s - 20;
//         break;
//       // white pawn moves 1 row
//       case list.indexOf(s - 10) === -1 && piece === 1 && getPiece(p, s - 10) === 0
//       && inspectMove(p, s, s - 10) !== false:
//         target = s - 10;
//         break;
//       // white pawn captures opponent to left side
//       case list.indexOf(s - 11) === -1 && piece === 1 && isOpponent(p, s - 11) === true
//       && inspectMove(p, s, s - 11) !== false:
//         target = s - 11;
//         break;
//       // white pawn captures opponent to right side
//       case list.indexOf(s - 9) === -1 && piece === 1 && isOpponent(p, s - 9) === true
//       && inspectMove(p, s, s - 9) !== false:
//         target = s - 9;
//         break;
//       // white pawn captures en passant opponent
//       case p.e !== null && list.indexOf(p.e) === -1 && piece === 1
//       && (s - p.e === 9 || s - p.e === 11) && inspectMove(p, s, p.e) !== false:
//         target = p.e;
//         break;
//       // black pawn on 2 row moves 2 squares
//       case list.indexOf(s + 20) === -1 && s > 10 && s < 19 && piece === 7
//       && getPiece(p, s + 10) === 0 && getPiece(p, s + 20) === 0
//       && inspectMove(p, s, s + 20) !== false:
//         target = s + 20;
//         break;
//       // black pawn moves 1 row
//       case list.indexOf(s + 10) === -1 && piece === 7 && getPiece(p, s + 10) === 0
//       && inspectMove(p, s, s + 10) !== false:
//         target = s + 10;
//         break;
//       // black pawn captures opponent to left side
//       case list.indexOf(s + 11) === -1 && piece === 7 && isOpponent(p, s + 11) === true
//       && inspectMove(p, s, s + 11) !== false:
//         target = s + 11;
//         break;
//       // black pawn captures opponent to right side
//       case list.indexOf(s + 9) === -1 && piece === 7 && isOpponent(p, s + 9) === true
//       && inspectMove(p, s, s + 9) !== false:
//         target = s + 9;
//         break;
//       // black pawn captures en passant opponent
//       case p.e !== null && list.indexOf(p.e) === -1 && piece === 7
//       && (s - p.e === -9 || s - p.e === -11) && inspectMove(p, s, p.e) !== false:
//         target = p.e;
//         break;
//       default: return false;
//     }
//     return target;
//   } catch (err) {
//     return err;
//   }
// }

function addMove(position, square, list = []) {
  try {
    const piece = getPiece(position, square);
    switch (true) {
      case piece === 0: return false;
      case isOwnPiece(position, square) === false: return false;
      case piece === 1 || piece === 7: return addPawnMove(position, square, list);
      case piece === 2 || piece === 8: return addKnightMove(position, square, list);
      case piece === 3 || piece === 9: return addLineMove(position, square, list);
      case piece === 4 || piece === 10: return addLineMove(position, square, list);
      case piece === 5 || piece === 11: return addLineMove(position, square, list);
      case piece === 6 || piece === 12: return addKingMove(position, square, list);
      default: return false;
    }
  } catch (err) {
    return err;
  }
}

function listMoves(position, square) {
  try {
    const piece = getPiece(position, square);
    switch (true) {
      case piece === 0: return false;
      case isOwnPiece(position, square) === false: return false;
      default:
    }
    const list = [];
    let target;
    do {
      target = addMove(position, square, list);
      if (target !== false) list.push(target);
    } while (target !== false);
    return list.length === 0 ? false : list;
  } catch (err) {
    return err;
  }
}

export {
  addMove,
  listMoves,
};
