import React from 'react';
import { connect } from 'react-redux';

import { gameSquareSize } from '../store/gameStore';

const mapStateToProps = (state) => state;

function Resizer(props) {
  const {
    min, dispatch,
  } = props;

  const { gameStore } = props;
  const { squareSize, factorWidth } = gameStore;
  // console.log(squareSize, factorWidth);
  const d = document;

  let x0;
  let y0;
  let x;
  let y;

  function resizeAll(e) {
    e.preventDefault();
    e.stopPropagation();
    switch (e.type) {
      case 'mousemove':
        d.addEventListener('mouseup', () => d.removeEventListener('mousemove', resizeAll));
        x = e.screenX;
        y = e.screenY;
        break;
      case 'touchmove':
        d.addEventListener('touchend', () => d.removeEventListener('touchmove', resizeAll));
        x = e.changedTouches[0].screenX;
        y = e.changedTouches[0].screenY;
        break;
      default:
    }

    if (x < x0 || y < y0) return false;
    const size = Math.min(x - x0, y - y0);
    if (size < min) return false;
    dispatch(gameSquareSize(size / 8));
    // console.log(size);
    return true;
  }

  return (
    <div
      style={{
        position: 'relative',
        cursor: 'nwse-resize',
      }}
      className="resizer"
      onMouseDown={
        (e) => {
          e.preventDefault();
          e.stopPropagation();
          d.addEventListener('mousemove', resizeAll);
          // x = e.clientX;
          // y = e.clientY;
          x0 = e.screenX - squareSize * 8;
          y0 = e.screenY - squareSize * 8;
          // console.log(x0, y0, squareSize);
        }
      }
      onMouseUp={
        (e) => {
          e.preventDefault();
          e.stopPropagation();
          d.removeEventListener('mousemove', resizeAll);
        }
      }
      onTouchStart={
        (e) => {
          // alert('touchstart');
          e.preventDefault();
          e.stopPropagation();
          d.addEventListener('touchmove', resizeAll);
          // x = e.clientX;
          // y = e.clientY;
          x0 = e.changedTouches[0].screenX - squareSize * 8;
          y0 = e.changedTouches[0].screenY - squareSize * 8;
          // alert(`x0=${x0}, y0=${y0}`);
          // x0 = e.screenX - squareSize * 8;
          // y0 = e.screenY - squareSize * 8;
          // console.log(x0, y0, squareSize);
        }
      }
      onTouchEnd={
        (e) => {
          e.preventDefault();
          e.stopPropagation();
          d.removeEventListener('touchmove', resizeAll);
        }
      }
    >
      <div
        style={{
          width: squareSize * factorWidth,
          transform: `translate(1px, ${squareSize * factorWidth * 0.5}px) rotate(-45deg)`,
          height: '1px',
          background: 'grey',
        }}
        className="resizer-line-1"
      >
        <div
          style={{
            height: `${squareSize * factorWidth * 0.5}px`,
            transform: `translate(${squareSize * factorWidth * 0.5}px, 0px) rotate(90deg)`,
            width: '1px',
            background: 'grey',
            content: '',
            position: 'absolute',
          }}
          className="resizer-line-2"
        />
        <div
          style={{
            height: `${squareSize * factorWidth * 0.1}px`,
            transform: `translate(${squareSize * factorWidth * 0.5}px, ${squareSize * factorWidth * 0.5 - squareSize * factorWidth * 0.1}px) rotate(90deg)`,
            width: '1px',
            background: 'grey',
            content: '',
            position: 'absolute',
          }}
          className="resizer-line-3"
        />
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(Resizer);
