import React from 'react';
import Piece from './Piece';
import Square from './Square';
import { SYMBOLS } from '../engine/constants';

function PromoDialog(props) {
  const {
    squareSize, zIndex, col, flip, w, stage, finishPromotion,
  } = props;
  const style = {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex,
    display: 'grid',
    gridTemplateColumns: `repeat(8, ${squareSize}px)`,
    gridTemplateRows: `repeat(8, ${squareSize}px)`,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  };
  const extraProps = {
    squareSize, finishPromotion, stage, hover: true,
  };
  return (
    <div className="promo-dialog" style={style}>
      <Square promo col={col} flip={flip} row={w ? 0 : 7}>
        <Piece piece={SYMBOLS[w ? 5 : 11]} {...extraProps} />
      </Square>
      <Square promo col={col} flip={flip} row={w ? 1 : 6}>
        <Piece piece={SYMBOLS[w ? 2 : 8]} {...extraProps} />
      </Square>
      <Square promo col={col} flip={flip} row={w ? 3 : 4}>
        <Piece piece={SYMBOLS[w ? 3 : 9]} {...extraProps} />
      </Square>
      <Square promo col={col} flip={flip} row={w ? 2 : 5}>
        <Piece piece={SYMBOLS[w ? 4 : 10]} {...extraProps} />
      </Square>
    </div>
  );
}

export default PromoDialog;
