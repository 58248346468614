import React from 'react';
import './Square.css';

function Square(props) {
  const {
    index,
    squareSize,
    children,
    activeSide,
    grid,
    promo,
    col,
    row,
    flip,
    last,
  } = props;

  // gridColumnStart: 5, gridRowStart: 5
  const col1 = 10 - Math.round((index - (index % 10)) / 10);
  const row1 = index % 10;
  // const color = (col1+ row) % 2 === 0 ? 'bl' : 'wh';
  const style1 = { width: `${squareSize}px`, height: `${squareSize}px`, position: 'relative' };
  switch (true) {
    case promo && !flip:
      style1.backgroundColor = '#cccbcb';
      // style1.borderRadius = '50%';
      style1.gridColumnStart = col || 0;
      style1.gridRowStart = row + 1 || 0;
      break;
    case promo && flip:
      style1.backgroundColor = '#cccbcb';
      // style1.borderRadius = '50%';
      style1.gridColumnStart = 9 - (col || 0);
      style1.gridRowStart = 8 - (row || 0);
      break;
    case (col1 + row1) % 2 === 0: style1.backgroundColor = '#b58863'; break;
    case (col1 + row1) % 2 !== 0: style1.backgroundColor = '#f0d9b5'; break;
    default: style1.backgroundColor = 'transparent';
  }

  const props1 = {};
  if (!activeSide && !promo) {
    props1.onDragOver = (e) => { // important!
      e.preventDefault();
      // e.stopPropagation();
    };
    props1.onDrop = (e) => { // important!
      e.preventDefault();
      // e.stopPropagation();
      // handleDrop(index);
    };
  }

  if (promo) props1.onMouseOver = (e) => { e.target.parentNode.style.backgroundColor = 'red'; };
  if (promo) props1.onMouseLeave = (e) => { e.target.parentNode.style.backgroundColor = '#cccbcb'; };

  return (
    <div
      data-index={index}
      className={`square ${last && 'last-move'}`}
      style={style1}
      {...props1}
    >
      {
        grid
        && <div style={{ position: 'absolute', fontSize: '10px' }}>{index}</div>
      }
      {children.props.piece === '-' ? null : children}
    </div>
  );
}

export default Square;
