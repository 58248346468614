import React, { useState } from 'react';
import './ImportGame.css';

function ImportGame() {
  const [text, setValue] = useState('');
  return (
    <div
      className="import-game"
    >
      <textarea value={text} onChange={(e) => setValue(e.target.value)} />
    </div>
  );
}

export default ImportGame;
