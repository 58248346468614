import { isOwnPiece } from './engine/tools';
import { makeMove } from './engine/moves';
import inspectMove from './engine/inspectMove';
import {
  touchPiece, incorrectMove, animateMove, showPromoDialog, moveEnd,
} from './store/positionStore';

// import { sendMessage } from './store/gameStore';

function handleClickTouch(state, dispatch, index, animate = true) {
  try {
    // console.log('handle click');
    // if (e.type === 'touchstart') alert('touchstart');
    // console.log(`index: ${index}, stage: ${stage}, touch=${touch} target=${target}`);
    const { touch, stage } = state;
    switch (true) {
      // if player touched own piece
      case stage === 1 && isOwnPiece(state, index):
        dispatch(touchPiece(index));
        break;
      // if player moved own piece
      case stage === 2 && !isOwnPiece(state, index): {
        const { type } = inspectMove(state, touch, index);
        // incorrect move
        if (!type) {
          dispatch(incorrectMove());
          return true;
        }
        // show animated move
        if (animate) {
          dispatch(animateMove(touch, index));
          return true;
        }
        // if pawn's promotion
        if (type === 2) {
          dispatch(showPromoDialog(touch, index));
          // setStage.bind(this)(5, touch, index);
          return true;
        }
        // regular move without animation
        dispatch(moveEnd(touch, index));
        // setStage.bind(this)(1, touch, index);
        break;
      }
      // player touch another own piece
      case stage === 2 && isOwnPiece(state, index):
        dispatch(touchPiece(index));
        break;
      default: break;
    }
    return true;
  } catch (err) {
    return err;
  }
}

const handleClickSquare = (state) => (dispatch) => (animate) => (e) => {
  try {
    const index = parseInt(e.target.dataset.index, 10);
    return handleClickTouch(state, dispatch, index, animate);
  } catch (err) {
    return err;
  }
};

const handleTouch = (state) => (dispatch) => (animate) => (e) => {
  try {
    let elem;
    if (e.type === 'touchend') {
      const changedTouch = e.changedTouches[0];
      elem = document.elementFromPoint(changedTouch.clientX, changedTouch.clientY);
      // alert(elem.dataset.index);
    } else if (e.type === 'touchstart') {
      elem = e.target;
    }
    const index = parseInt(elem.dataset.index, 10);
    e.preventDefault();
    e.stopPropagation();
    // dispatch(sendMessage(index));
    return handleClickTouch(state, dispatch, index, animate);
  } catch (err) {
    return err;
  }
};

const moveAnimationEnd = (state) => (dispatch) => () => {
  try {
    // console.log('move animation end');
    // this.dispatch = dispatch.bind(this);
    const { stage, touch, target } = state;
    if (stage !== 4) return false;
    const { type } = inspectMove(state, touch, target);
    // console.log(moveType);
    if (!type) return false;
    if (type === 2) {
      dispatch(showPromoDialog(touch, target));
      return true;
    }
    if (stage === 4) dispatch(moveEnd(touch, target));
    // setStage.bind(this)(1, touch, target);
    return true;
  } catch (err) {
    console.log(err.message);
    return err;
  }
};

const finishPromotion = (state) => (dispatch) => (piece) => {
  try {
    // console.log(`finish promotion ${piece}`);
    const { stage, touch, target } = state;
    if (stage === 5) dispatch(moveEnd(touch, target, piece));
    return true;
  } catch (err) {
    return err;
  }
};

const handleDragStart = (state) => (dispatch) => (e) => {
  try {
    const index = parseInt(e.target.dataset.index, 10);
    const { stage } = state;
    switch (true) {
      // if player grag own piece
      case (stage === 1 || stage === 2) && isOwnPiece(state, index):
        dispatch(touchPiece(index));
        break;
      default: break;
    }
    return true;
  } catch (err) {
    return err;
  }
};

const handleDragEnd = (state) => (dispatch) => () => {
  try {
    const { stage } = state;
    if (stage === 5) return true;
    dispatch(incorrectMove());
    return true;
  } catch (err) {
    return err;
  }
};

const handleDrop = (state) => (dispatch) => (e) => {
  try {
    const index = parseInt(e.target.dataset.index, 10);
    const { touch, stage } = state;
    // console.log(index);
    // let moveType;
    switch (true) {
      // impossible move
      case stage === 2 && isOwnPiece(state, index):
        dispatch(incorrectMove());
        break;
      case stage === 2 && touch !== index: {
        const { type } = inspectMove(state, touch, index);
        // impossible move
        if (!type) {
          dispatch(incorrectMove());
          // setStage.bind(this)(1, null, null);
          return true;
        }
        if (type === 2) {
          // setStage.bind(this)(5, touch, index);
          dispatch(showPromoDialog(touch, index));
          return true;
        }
        // make move
        dispatch(moveEnd(touch, index));
        // setStage.bind(this)(1, touch, index);
        break;
      }
      default: break;
    }
    return true;
  } catch (err) {
    return err;
  }
};

export {
  handleClickSquare,
  handleDragStart,
  handleDragEnd,
  handleDrop,
  moveAnimationEnd,
  makeMove,
  finishPromotion,
  handleTouch,
};
