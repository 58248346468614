import React from 'react';
import { connect } from 'react-redux';
import { pos2fen } from '../engine/tools';
import {
  flipBoard, toggleAnimation, toggleGrid, setPointer, updateFen,
} from '../store/gameStore';
import { setPosition } from '../store/positionStore';

const mapStateToProps = (state) => state;

function Controls(props) {
  const { dispatch, gameStore, positionStore } = props;
  const {
    animate,
    squareSize,
    history,
    pointer,
  } = gameStore;
  // const style = animate ? {} : { filter: 'invert(50%)' };

  const { gameResult, EVL } = positionStore;

  const { w, b } = gameResult || {};
  const info = gameResult !== undefined ? `${w} : ${b} - ${EVL}` : '';

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <a
        style={{ marginRight: '15px' }}
        href="#rotate"
        title="flip board"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          dispatch(flipBoard());
        }}
      >
        <img src="./images/swap_vert-24px.svg" alt="flip" />
      </a>
      <a
        href="#animate"
        title="animate moves"
        style={{ filter: !animate && 'invert(50%)', marginRight: '15px' }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          dispatch(toggleAnimation());
        }}
      >
        <img src="./images/sports_handball-24px.svg" alt="animate" />
      </a>
      <a
        href="#show-grid"
        title="show grid"
        style={{ marginRight: '15px' }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          dispatch(toggleGrid());
        }}
      >
        <img src="./images/apps-24px.svg" alt="grid" />
      </a>
      <a
        href="#game-start"
        title="move to start"
        style={{ marginRight: '15px', filter: pointer === 0 && 'invert(50%)' }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const newPointer = 0;
          dispatch(setPosition(history[newPointer].position));
          dispatch(setPointer(newPointer));
          dispatch(updateFen(pos2fen(history[newPointer].position)));
        }}
      >
        <img src="./images/first_page-24px.svg" alt="game-start" />
      </a>
      <a
        href="#move-back"
        title="move back"
        style={{ marginRight: '15px', filter: pointer === 0 && 'invert(50%)' }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const newPointer = pointer > 0 ? pointer - 1 : 0;
          dispatch(setPosition(history[newPointer].position));
          dispatch(setPointer(newPointer));
          dispatch(updateFen(pos2fen(history[newPointer].position)));
        }}
      >
        <img src="./images/arrow_back-24px.svg" alt="move-back" />
      </a>
      <a
        href="#move-forward"
        title="move forward"
        style={{ marginRight: '15px', filter: pointer === history.length - 1 && 'invert(50%)' }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const newPointer = pointer < history.length - 1 ? pointer + 1 : history.length - 1;
          dispatch(setPosition(history[newPointer].position));
          dispatch(setPointer(newPointer));
          dispatch(updateFen(pos2fen(history[newPointer].position)));
        }}
      >
        <img src="./images/arrow_forward-24px.svg" alt="move-forward" />
      </a>
      <a
        href="#game-end"
        title="move to end"
        style={{ marginRight: '15px', filter: pointer === history.length - 1 && 'invert(50%)' }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const newPointer = history.length - 1;
          dispatch(setPosition(history[newPointer].position));
          dispatch(setPointer(newPointer));
          dispatch(updateFen(pos2fen(history[newPointer].position)));
        }}
      >
        <img src="./images/last_page-24px.svg" alt="game-end" />
      </a>
      <input
        type="text"
        readOnly
        style={{
          fontSize: `${Math.max(8, squareSize / 5)}px`,
          width: `${squareSize * 3}px`,
          height: `${squareSize / 2.5}px`,
          border: '1px solid gray',
          borderRadius: '3px',
          paddingLeft: '5px',
        }}
        value={info}
      />
    </div>
  );
}

export default connect(mapStateToProps)(Controls);
