import React from 'react';
import { connect } from 'react-redux';

import { quickEvaluate } from '../store/positionStore';
import { updateFen, initGame, flipBoard } from '../store/gameStore';

import { validateFen, fen2pos } from '../engine/tools';

const mapStateToProps = (state) => state;

function FenInformer(props) {
  const { gameStore, dispatch } = props;

  const { fen, squareSize } = gameStore;
  if (fen === undefined) return null;

  return (
    <div
      className="fen"
      currenfen={fen}
      style={{
        position: 'relative',
        display: 'flex',
        flexflow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '60px',
      }}
    >
      <span style={{ marginRight: squareSize * 0.2, fontSize: `${Math.max(8, squareSize / 3.5)}px` }}>FEN:</span>
      <input
        // readOnly
        style={{
          width: squareSize * 6,
          fontSize: `${Math.max(8, squareSize / 5)}px`,
          height: `${squareSize / 2.5}px`,
          borderRadius: '3px',
          border: '1px solid grey',
          paddingLeft: '5px',
        }}
        value={fen}
        onClick={(e) => e.target.select()}
        onTouchStart={(e) => e.target.select()}
        onChange={(e) => {
          e.preventDefault();
          e.stopPropagation();
          const { value } = e.target;
          if (validateFen(value)) {
            e.target.style.borderColor = 'grey';
            const position = fen2pos(value);
            dispatch(initGame(position));
            dispatch(quickEvaluate());
            if (position.w && gameStore.flip === true) dispatch(flipBoard());
            if (!position.w && gameStore.flip === false) dispatch(flipBoard());
          } else e.target.style.borderColor = 'red';
          dispatch(updateFen(value));
        }}
        // onKeyPress={(e) => { if (e.key === 'Enter') updateInitFen(e.target.value); }}
      />
    </div>
  );
}

export default connect(mapStateToProps)(FenInformer);
