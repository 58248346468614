import {
  getPiece,
} from './tools';

function isAttackedByKnight(position, square, white = true) {
  try {
    const p = position;
    const s = square;
    const moveRange = [-8, -12, -19, -21, 8, 12, 19, 21];
    for (let i = 0; i < moveRange.length; i += 1) {
      const piece = getPiece(p, s + moveRange[i]);
      if (piece === 2 && white) return true;
      if (piece === 8 && !white) return true;
    }
    return false;
  } catch (err) {
    return err;
  }
}

function isAttackedByRookOrQueen(position, square, white = true) {
  try {
    const p = position;
    const s = square;
    const sides = [1, -1, 10, -10];
    for (let i = 0; i < sides.length; i += 1) {
      let x = s + sides[i];
      let piece = getPiece(p, x);
      while (piece !== null) {
        if ((piece === 4 || piece === 5) && white === true) return true;
        if ((piece === 10 || piece === 11) && white === false) return true;
        if (piece !== 0) break;
        x += sides[i];
        piece = getPiece(p, x);
      }
    }
    return false;
  } catch (err) {
    return err;
  }
}

function isAttackedByPawn(position, square, white = true) {
  try {
    const p = position;
    const s = square;
    switch (true) {
      case white && (getPiece(p, s + 11) === 1 || getPiece(p, s + 9) === 1):
        return true;
      case p.e !== null && white && (p.e + 10) === s
        && (getPiece(p, s - 1) === 1 || getPiece(p, s + 1) === 1):
        return true;
      case !white && (getPiece(p, s - 11) === 7 || getPiece(p, s - 9) === 7):
        return true;
      case p.e !== null && !white && (p.e - 10) === s
        && (getPiece(p, s - 1) === 7 || getPiece(p, s + 1) === 7):
        return true;
      default:
    }
    return false;
  } catch (err) {
    return err;
  }
}

function isAttackedByBishopOrQueen(position, square, white = true) {
  try {
    const p = position;
    const s = square;
    const sides = [9, 11, -9, -11];
    for (let i = 0; i < sides.length; i += 1) {
      let x = s + sides[i];
      let piece = getPiece(p, x);
      while (piece !== null) {
        if ((piece === 3 || piece === 5) && white === true) return true;
        if ((piece === 9 || piece === 11) && white === false) return true;
        if (piece !== 0) break;
        x += sides[i];
        piece = getPiece(p, x);
      }
    }
    return false;
  } catch (err) {
    return err;
  }
}

function isAttackedByKing(position, square, white = true) {
  try {
    const p = position;
    const s = square;
    const moveRange = [11, 10, 9, 1, -11, -10, -9, -1];
    for (let i = 0; i < moveRange.length; i += 1) {
      const piece = getPiece(p, s + moveRange[i]);
      if (piece === 6 && white) return true;
      if (piece === 12 && !white) return true;
    }
    return false;
  } catch (err) {
    return err;
  }
}

// white = true if white's turn to move
function isAttacked(position, square, white = true) {
  try {
    if (isAttackedByRookOrQueen(position, square, white)) return true;
    if (isAttackedByBishopOrQueen(position, square, white)) return true;
    if (isAttackedByPawn(position, square, white)) return true;
    if (isAttackedByKing(position, square, white)) return true;
    if (isAttackedByKnight(position, square, white)) return true;
    return false;
  } catch (err) {
    return err;
  }
}

export default isAttacked;
