import { uci, getPiece, findPieces } from './tools';
import { SYMBOLS } from './constants';
import inspectMove from './inspectMove';
import { makeMove } from './moves';
import { quickEval } from './evaluate';

function ordinaryMove(position, s1, s2, capture) {
  try {
    const cap = capture !== false ? 'x' : '';
    const piece = getPiece(position, s1);
    const list = findPieces(position, piece);
    if (list.length === 0) return false;
    if (list.length === 1) return SYMBOLS[piece].toUpperCase() + cap + uci(s2);
    const list2 = list.filter((item) => inspectMove(position, item, s2) !== false && item !== s1);
    if (list2.length === 0) return SYMBOLS[piece].toUpperCase() + cap + uci(s2);
    const list3 = list2.filter((item) => uci(item)[0] === uci(s1)[0]);
    if (list3.length === 0) return SYMBOLS[piece].toUpperCase() + uci(s1)[0] + cap + uci(s2);
    const list4 = list2.filter((item) => uci(item)[1] === uci(s1)[1]);
    if (list4.length === 0) return SYMBOLS[piece].toUpperCase() + uci(s1)[1] + cap + uci(s2);
    return SYMBOLS[piece].toUpperCase() + uci(s1) + cap + uci(s2);
  } catch (err) {
    return err;
  }
}

function move2pgn(position, move) {
  try {
    const { promo, s1, s2 } = move;
    const { type, capture } = inspectMove(position, s1, s2, promo);
    if (type === undefined) return false;
    let result;
    switch (true) {
      case type === 2 && capture === false: result = `${uci(s2)}=${SYMBOLS[promo].toUpperCase()}`; break;
      case type === 2 && capture !== false: result = `${uci(s1)[0]}x${uci(s2)}=${SYMBOLS[promo].toUpperCase()}`; break;
      case type === 4 && capture === false: result = uci(s2); break;
      case type === 10 || type === 12: result = 'O-O'; break;
      case type === 11 || type === 13: result = 'O-O-O'; break;
      case type === 5 && capture === false: result = uci(s2); break;
      case type === 5 && capture !== false: result = `${uci(s1)[0]}x${uci(s2)}`; break;
      case type === 3 && capture !== false: result = `${uci(s1)[0]}x${uci(s2)}`; break;
      case type === 1 || type > 19: result = ordinaryMove(position, s1, s2, capture); break;
      // case type === 1 && capture !== false: result = ordinaryMove(position, s1, s2, true);
      default: result = uci(s1) + uci(s2); break;
      // + (promo === null ? '' : SYMBOLS[promo].toUpperCase())
      // + EVL;
    }
    const { EVL } = quickEval(makeMove(position, s1, s2, promo));
    const trans = { check: '+', checkmate: '#' };
    const evaluation = EVL ? trans[EVL] : '';
    return result + evaluation;
  } catch (err) {
    return err;
  }
}

export {
  move2pgn,
};
