/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { getPiece, isOpponent } from './tools';
import inspectMove from './inspectMove';

// types of moves see in constants.js

function makeMove(position, from, to, promo = null) {
  try {
    const A = from;
    const B = to;
    const { type, capture } = inspectMove(position, A, B);
    // console.log(type, movesTypes[type]);
    if (!type) return false;
    const nextState = produce(position, (draft) => {
      draft.last = {
        s1: A,
        s2: B,
        promo,
        type,
        capture: isOpponent(position, B) ? getPiece(position, B) : null,
      };
      const piece = getPiece(position, A);
      // trace king's position
      if (piece === 12) draft.BK = B;
      if (piece === 6) draft.WK = B;
      draft.b[A] = 0;
      draft.m[0] += 1;
      // pawn promotion
      draft.b[B] = type === 2 ? promo : piece;
      // pawn 2 square advance
      draft.e = type === 4 ? (A + B) / 2 : null;
      // en passant capture
      if (type === 3) {
        const d = position.w ? 10 : -10;
        draft.b[B + d] = 0;
      }
      // castlings disabled DEFINE TYPES HERE !!!
      if (position.w && to === 1) draft.c[3] = false;
      if (position.w && to === 8) draft.c[2] = false;
      if (!position.w && to === 71) draft.c[1] = false;
      if (!position.w && to === 78) draft.c[0] = false;

      if (type > 99 && type < 104) draft.c[type - 100] = false;

      if (type === 20) { draft.c[0] = false; draft.c[1] = false; }
      if (type === 21) { draft.c[2] = false; draft.c[3] = false; }

      // halfmove counter reset
      if ((type > 1 && type < 6) || capture) {
        draft.m[0] = 0;
      }
      if (type === 10) { // white short castling
        draft.b[78] = 0;
        draft.b[76] = 4;
        draft.c[0] = false;
        draft.c[1] = false;
      }
      if (type === 11) { // white long castling
        draft.b[71] = 0;
        draft.b[74] = 4;
        draft.c[0] = false;
        draft.c[1] = false;
      }
      if (type === 12) { // black short castling
        draft.b[8] = 0;
        draft.b[6] = 10;
        draft.c[2] = false;
        draft.c[3] = false;
      }
      if (type === 13) { // black long castling
        draft.b[1] = 0;
        draft.b[4] = 10;
        draft.c[2] = false;
        draft.c[3] = false;
      }
      draft.w = !position.w;
      draft.touch = null;
      draft.target = null;
      draft.stage = 1;
      if (position.w === false) draft.m[1] += 1;
    });
    return nextState;
  } catch (err) {
    return err;
  }
}

function takeMove(position) {
  try {
    const nextState = produce(position, (draft) => {
      const { history } = position;
      if (history.length === 0) return;
      const move = history[history.length - 1];
      const piece = getPiece(position, move.s2);
      draft.b[move.s2] = move.capture || 0;
      draft.b[move.s1] = piece;
      draft.w = !position.w;
      console.log(move, piece);
      // draft.test = move;
    });
    return nextState;
  } catch (err) {
    return err;
  }
}

export {
  makeMove,
  takeMove,
};
