/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { pos2fen } from '../engine/tools';

// const INIT_GAME_STORE = 'INIT_GAME_STORE';
const GAME_SQUARE_SIZE = 'GAME_SQUARE_SIZE';
const UPDATE_FEN = 'UPDATE_FEN';
const FLIP_BOARD = 'FLIP_BOARD';
const TOGGLE_ANIMATION = 'TOGGLE_ANIMATION';
const SEND_MESSAGE = 'SEND_MESSAGE';
const TOGGLE_GRID = 'TOGGLE_GRID';
const ADD_TO_HISTORY = 'ADD_TO_HISTORY';
const SET_POINTER = 'SET_POINTER';
const INIT_GAME = 'INIT_GAME';

// const initGameStore = (payload) => ({ type: INIT_GAME_STORE, payload });
const gameSquareSize = (num) => ({ type: GAME_SQUARE_SIZE, payload: num });
const updateFen = (fen) => ({ type: UPDATE_FEN, payload: fen });
const flipBoard = () => ({ type: FLIP_BOARD, payload: '' });
const toggleAnimation = () => ({ type: TOGGLE_ANIMATION, payload: '' });
const sendMessage = (message) => ({ type: SEND_MESSAGE, payload: message });
const toggleGrid = () => ({ type: TOGGLE_GRID, payload: '' });
const addToHistory = (position) => ({ type: ADD_TO_HISTORY, payload: position });
const setPointer = (pointer) => ({ type: SET_POINTER, payload: pointer });
const initGame = (position) => ({ type: INIT_GAME, payload: position });

const dispatcher = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case INIT_GAME: return produce(state, (draft) => {
      draft.fen = pos2fen(payload);
      draft.started = new Date();
      draft.pointer = 0;
      draft.history = [];
      draft.history.push({ id: (payload.m[1] || 1) - 1, position: { ...payload, stage: 1 } });
    });
    case SET_POINTER: return { ...state, pointer: payload };
    case GAME_SQUARE_SIZE: return { ...state, squareSize: payload };
    // case INIT_GAME_STORE: return state;
    case UPDATE_FEN: return { ...state, fen: payload };
    case FLIP_BOARD: return { ...state, flip: !state.flip };
    case TOGGLE_ANIMATION: return { ...state, animate: !state.animate };
    case SEND_MESSAGE: return { ...state, message: payload };
    case TOGGLE_GRID: return { ...state, grid: !state.grid };
    case ADD_TO_HISTORY: return produce(state, (draft) => {
      if (!state.history) draft.history = [{ id: 0, position: payload }];
      if (!state.pointer) draft.pointer = 0;
      // console.log(draft.history, draft.pointer);
      if (draft.history.length > (draft.pointer + 1)) {
        draft.history = draft.history.slice(0, draft.pointer + 1);
      }
      draft.pointer = draft.history.length;
      const id = draft.history[draft.history.length - 1].id + 1;
      draft.history.push({
        id,
        position: { ...payload, stage: payload.stage || 1 }, // IMPORTANT stage !== 0
      });
    });
    // case RECEIVE_DATA: return { ...state, data: payload };
    default: return state;
  }
};

export default dispatcher;
export {
  // initGameStore,
  gameSquareSize,
  updateFen,
  flipBoard,
  toggleAnimation,
  sendMessage,
  toggleGrid,
  addToHistory,
  setPointer,
  initGame,
};
