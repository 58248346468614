/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import {
  getPiece, isOwnPiece, isOpponent, offBoard,
} from './tools';

import isAttacked from './isAttacked';

function inspectRookMove(position, from, to) {
  try {
    const p = position;
    const A = from;
    const B = to;
    let D;
    let result = 1;

    if (A === 78 && p.w && p.c[0]) result = 100; // short white castling disabled
    if (A === 71 && p.w && p.c[1]) result = 101; // long white castling disabled
    if (A === 8 && !p.w && p.c[2]) result = 102; // short black castling disabled
    if (A === 1 && !p.w && p.c[3]) result = 103; // long black castling disabled

    switch (true) {
      case B < A && B % 10 === A % 10: D = -10; break;
      case B > A && B - A < 8: D = 1; break;
      case B > A && B % 10 === A % 10: D = 10; break;
      case B < A && A - B < 8: D = -1; break;
      default: return false;
    }
    for (let x = A + D; getPiece(p, x) !== null; x += D) {
      if (isOwnPiece(p, x)) return false;
      if (x === B) return result; // 1 or 100 - 103
      if (isOpponent(p, x)) return false;
    }
    return false;
  } catch (err) {
    return err;
  }
}

function inspectQueenMove(position, from, to) {
  try {
    const p = position;
    const A = from;
    const B = to;
    let D;
    switch (true) {
      case B < A && B % 10 === A % 10: D = -10; break;
      case B > A && B - A < 8: D = 1; break;
      case B > A && B % 10 === A % 10: D = 10; break;
      case B < A && A - B < 8: D = -1; break;
      case B < A && (A - B) % 11 === 0: D = -11; break;
      case B < A && (A - B) % 9 === 0: D = -9; break;
      case B > A && (B - A) % 9 === 0: D = 9; break;
      case B > A && (B - A) % 11 === 0: D = 11; break;
      default: return false;
    }
    for (let x = A + D; getPiece(p, x) !== null; x += D) {
      if (isOwnPiece(p, x)) return false;
      if (x === B) return 1;
      if (isOpponent(p, x)) return false;
    }
    return false;
  } catch (err) {
    return err;
  }
}

function inspectKingMove(position, from, to) {
  try {
    const p = position;
    const A = from;
    const B = to;
    const D = B - A;
    switch (true) {
      case A === 75 && B === 77 && getPiece(p, 76) === 0 && getPiece(p, 77) === 0
        && p.c[0] === true
        && isAttacked(p, 76, false) === false && isAttacked(p, 75, false) === false:
        return 10; // white short castling
      case A === 75 && B === 73 && getPiece(p, 74) === 0 && getPiece(p, 73) === 0
        && getPiece(p, 72) === 0 && p.c[1] === true
        && isAttacked(p, 74, false) === false && isAttacked(p, 75, false) === false:
        return 11; // white long castling
      case A === 5 && B === 7 && getPiece(p, 6) === 0 && getPiece(p, 7) === 0
        && p.c[2] === true
        && isAttacked(p, 6, true) === false && isAttacked(p, 5, true) === false:
        return 12; // black short castling
      case A === 5 && B === 3 && getPiece(p, 4) === 0 && getPiece(p, 3) === 0
        && getPiece(p, 2) === 0 && p.c[3] === true
        && isAttacked(p, 4, true) === false && isAttacked(p, 5, true) === false:
        return 13; // black long castling
      default:
    }

    const moveRange = [1, 11, 10, 9, -1, -11, -10, -9];
    if (moveRange.indexOf(D) === -1) return false;
    if (isOwnPiece(p, B)) return false;

    switch (true) {
      case A === 75 && getPiece(p, A) === 6:
        return 20; // white king move - disable both white castlings
      case A === 5 && getPiece(p, A) === 12:
        return 21; // black king move - disable both black castlings
      default: return 1; // ordinary king move
    }
  } catch (err) {
    return err;
  }
}

function inspectBishopMove(position, from, to) {
  try {
    const p = position;
    const A = from;
    const B = to;
    let D;
    switch (true) {
      case B < A && (A - B) % 11 === 0: D = -11; break;
      case B < A && (A - B) % 9 === 0: D = -9; break;
      case B > A && (B - A) % 9 === 0: D = 9; break;
      case B > A && (B - A) % 11 === 0: D = 11; break;
      default: return false;
    }
    for (let x = A + D; getPiece(p, x) !== null; x += D) {
      if (isOwnPiece(p, x)) return false;
      if (x === B) return 1;
      if (isOpponent(p, x)) return false;
    }
    return false;
  } catch (err) {
    return err;
  }
}

function inspectKnightMove(position, from, to) {
  try {
    const p = position;
    const A = from;
    const B = to;
    const D = B - A;
    const moveRange = [-8, -12, -19, -21, 8, 12, 19, 21];
    if (moveRange.indexOf(D) === -1) return false;
    if (isOwnPiece(p, B)) return false;
    return 1;
  } catch (err) {
    return err;
  }
}

function inspectPawnMove(position, from, to) {
  try {
    const p = position;
    const A = from;
    const B = to;
    const D = B - A;

    const piece = getPiece(p, A);
    // console.log(`piece: ${piece}, A: ${A}, B: ${B}, D: ${D}`);
    switch (true) {
      // white pawn advanced 2 squares forward
      case piece === 1 && D === -20 && getPiece(p, A - 10) === 0 && getPiece(p, A - 20) === 0
      && A > 60 && A < 69:
        return 4;
      // promotion or ordinary move - white pawn advanced 1 squares forward
      case piece === 1 && D === -10 && getPiece(p, A - 10) === 0:
        return B > 0 && B < 9 ? 2 : 5;
      // promotion or ordinary move - white pawn took opponent's piece
      case piece === 1 && (D === -9 || D === -11) && isOpponent(p, B):
        return B > 0 && B < 9 ? 2 : 5;
      // en passant capture move by white
      case piece === 1 && (D === -9 || D === -11) && p.e === B:
        return 3;
      // black pawn advanced 2 squares forward
      case piece === 7 && D === 20 && getPiece(p, A + 10) === 0 && getPiece(p, A + 20) === 0
      && A > 10 && A < 19:
        return 4;
      // promotion or ordinary move - black pawn advanced 1 squares forward
      case piece === 7 && D === 10 && getPiece(p, A + 10) === 0:
        return B > 70 && B < 79 ? 2 : 5;
      // promotion or ordinary move - black pawn took opponent's piece
      case piece === 7 && (D === 9 || D === 11) && isOpponent(p, B):
        return B > 70 && B < 79 ? 2 : 5;
      // en passant capture move by black
      case piece === 7 && (D === 9 || D === 11) && p.e === B:
        return 3;
      default: return false;
    }
  } catch (err) {
    return err;
  }
}

function inspectMove(position, from, to, promo = null) {
  try {
    const piece = getPiece(position, from);
    if (offBoard(position, to) === true) return false;
    switch (true) {
      case piece === 0: return false;
      case isOwnPiece(position, from) === false: return false;
      default:
    }
    // detect jeneral capture move
    let capture = isOpponent(position, to) ? getPiece(position, to) : false;

    const nextState = produce(position, (draft) => {
      // simplified move emulation for detecting impossible position
      // due to king's attack
      draft.b[from] = 0;
      draft.b[to] = piece;
      draft.w = !position.w;
      if (piece === 6) draft.WK = to;
      if (piece === 12) draft.BK = to;
      if (position.e && position.e === to && piece === 1) {
        draft.b[to + 10] = 0;
        capture = 7;
      } else if (position.e && position.e === to && piece === 7) {
        draft.b[to - 10] = 0;
        capture = 1;
      }
    });
    // console.log('WK', nextState.WK);
    // console.log('BK', nextState.BK);
    // console.log('W', nextState.w);
    // console.log('isAttacked', nextState.w === true && isAttacked(nextState, nextState.BK, true));
    if (nextState.w === true && isAttacked(nextState, nextState.BK, true)) return false;
    if (nextState.w === false && isAttacked(nextState, nextState.WK, false)) return false;

    // detect jeneral move type
    let type = false;
    switch (true) {
      case piece === 1 || piece === 7: type = inspectPawnMove(position, from, to, promo); break;
      case piece === 2 || piece === 8: type = inspectKnightMove(position, from, to); break;
      case piece === 3 || piece === 9: type = inspectBishopMove(position, from, to); break;
      case piece === 4 || piece === 10: type = inspectRookMove(position, from, to); break;
      case piece === 5 || piece === 11: type = inspectQueenMove(position, from, to); break;
      case piece === 6 || piece === 12: type = inspectKingMove(position, from, to); break;
      default: return false;
    }
    if (type === false) return false;
    return { type, capture };
  } catch (err) {
    return err;
  }
}

export default inspectMove;
