import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BoardFrame from './BoardFrame';
import FenInformer from './FenInformer';
import Controls from './Controls';
import History from './History';
import ImportGame from './ImportGame';
import { fen2pos } from '../engine/tools';

import { quickEvaluate } from '../store/positionStore';
import { flipBoard, initGame } from '../store/gameStore';

// import { initGameStore } from './store/gameStore';

const mapStateToProps = (state) => state;

function Game(props) {
  const { gameStore, dispatch } = props;
  const {
    flip, squareSize, factorWidth, fen,
  } = gameStore;
  useEffect(() => {
    if (gameStore.started === undefined) {
      const position = fen2pos(fen);
      dispatch(initGame(position));
      dispatch(quickEvaluate());
      if (position.w && gameStore.flip === true) dispatch(flipBoard());
      if (!position.w && gameStore.flip === false) dispatch(flipBoard());
    }
  });
  if (gameStore.started === undefined) return null;
  return (
    <div
      className="game"
      style={{
        position: 'relative',
        width: `${squareSize * (8 + factorWidth)}px`,
      }}
    >
      <BoardFrame
        squareSize={squareSize}
        factorWidth={factorWidth}
        flip={flip}
      />
      <FenInformer />
      <Controls />
      <History />
      <ImportGame />
    </div>
  );
  // }
}

export default connect(mapStateToProps, null)(Game);
