import React from 'react';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { createLogger } from 'redux-logger';

import positionStore, { moveEnd, quickEvaluate } from '../store/positionStore';
import gameStore, { updateFen, addToHistory } from '../store/gameStore';
import Game from './Game';
import { fen2pos, pos2fen } from '../engine/tools';

const logger = createLogger();

const moveEndType = moveEnd().type;

const middleware = () => ({ getState, dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === moveEndType) {
    dispatch(quickEvaluate());
    dispatch(addToHistory(getState().positionStore));
    dispatch(updateFen(pos2fen(getState().positionStore)));
  }
};

const fens = [
  'r3k2r/8/8/8/8/8/8/R3K2R w KQkq - 0 1',
  '8/8/8/8/8/3k4/3p4/3K4 w - - 14 10',
  '8/8/8/8/8/4k3/3p4/4K3 w - - 12 9',
  'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  'rnb1kb1r/pppp1ppp/7n/4p3/2K1PP1q/6P1/PPPP3P/RNBQ1BNR b kq - 6 6',
  'rnb1kbnr/pppp1ppp/8/4p3/4PP1q/8/PPPP2PP/RNBQKBNR w KQkq - 1 3',
  'rnbqkbnr/ppp1ppPp/8/8/8/8/PPpPP1PP/RNBQKBNR w KQkq - 0 5',
  'rnbqkbnr/pp1ppppp/8/2p5/4P3/8/PPPP1PPP/RNBQKBNR w KQkq c6 0 2',
  'rnbqkbnr/pppppppp/8/8/4P3/8/PPPP1PPP/RNBQKBNR b KQkq e3 0 1',
  'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  'r3k2r/p1ppqpb1/bn2pnp1/3PN3/1p2P3/2N2Q1p/PPPBBPPP/R3K2R w KQkq - 0 1',
  'r3k2r/8/8/8/8/8/8/R3K2R w KQkq - 0 1',
  'r3k2r/8/8/8/8/8/8/R3K2R b KQkq - 0 1',
  '8/8/4k3/3Nn3/3nN3/4K3/8/8 w - - 0 1',
  'n1n5/PPPk4/8/8/8/8/4Kppp/5N1N w - - 0 1 ',
];

let width = 60;
let display = true;
if ('ontouchstart' in document.documentElement) {
  width = Math.min(window.screen.width, 500) / 8.5;
  display = false;
}

const fen1 = fens[3];
const fen2 = fens[2];

const store1 = createStore(
  combineReducers({
    gameStore,
    positionStore,
  }),
  {
    gameStore: {
      fen: fen1, flip: false, animate: true, squareSize: width, factorWidth: 0.3, grid: false,
    },
    positionStore: {},
  },
  applyMiddleware(logger, middleware()),
);

const store2 = createStore(
  combineReducers({
    gameStore,
    positionStore,
  }),
  {
    gameStore: {
      fen: fen2, flip: false, animate: true, squareSize: width, factorWidth: 0.3, grid: true,
    },
    positionStore: { ...fen2pos(fen2) },
  },
  applyMiddleware(logger, middleware()),
);

function App() {
  return (
    <div
      className="container"
      style={{
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        userSelect: 'none',
      }}
    >
      <div style={{ margin: '40px 0' }}>
        <Provider store={store1}>
          <Game />
        </Provider>
      </div>
      {
        false && display && (
          <div style={{ margin: '40px 0' }}>
            <Provider store={store2}>
              <Game />
            </Provider>
          </div>
        )
      }
    </div>
  );
}

export default App;
