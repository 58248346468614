const PIECES = {
  '-': 0, P: 1, N: 2, B: 3, R: 4, Q: 5, K: 6, p: 7, n: 8, b: 9, r: 10, q: 11, k: 12,
};
const SYMBOLS = {
  0: '-', 1: 'P', 2: 'N', 3: 'B', 4: 'R', 5: 'Q', 6: 'K', 7: 'p', 8: 'n', 9: 'b', 10: 'r', 11: 'q', 12: 'k',
};

const movesTypes = {
  false: 'impossible move',
  1: 'ordinary move',
  2: 'pawn promotion',
  3: 'en passant capture',
  4: '2 square pawn advance',
  5: 'ordinary pawn move',
  // 6: 'piece capture',
  // 7: 'rook capture - castling disabled',
  10: 'white short castling - disable both castling',
  11: 'white long castling - disable both castling',
  12: 'black short castling - disable both castling',
  13: 'black long castling - disable both castling',

  20: 'white king moves - white castlings disabled',
  21: 'black king moves - black castlings disabled',

  100: 'white king rook moves - short castling disabled',
  101: 'white queen rook moves - long castling disabled',
  102: 'black king rook moves - short castling disabled',
  103: 'black queen rook moves - long castling disabled',
};

export {
  PIECES,
  SYMBOLS,
  movesTypes,
};
