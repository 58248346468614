import { addMove } from './generateMove';
import isAttacked from './isAttacked';

// to list black pieces set white = false
function listPieces(position, white = true) {
  const list = [];
  for (let i = 1; i < 79; i += 1) {
    const piece = position.b[i];
    if (white && piece > 0 && piece < 7) list.push(i);
    if (!white && piece > 6 && piece < 13) list.push(i);
  }
  return list;
}

function getAnyMove(position) {
  const list = listPieces(position, position.w);
  // console.log('list', list);
  for (let i = 0; i < list.length; i += 1) {
    const move = addMove(position, list[i]);
    if (move) {
      // console.log('move', move);
      return move;
    }
  }
  return false;
}

function quickEval(position) {
  const move = getAnyMove(position);
  if (move === false) {
    switch (true) {
      case position.w === true && isAttacked(position, position.WK, false):
        return { white: 0, black: 1, EVL: 'checkmate' };
      case position.w === false && isAttacked(position, position.BK, true):
        return { white: 1, black: 0, EVL: 'checkmate' };
      default: return { white: '½', black: '½', EVL: 'stalemate' };
    }
  } else {
    switch (true) {
      case position.w === true && isAttacked(position, position.WK, false):
        return { EVL: 'check' };
      case position.w === false && isAttacked(position, position.BK, true):
        return { EVL: 'check' };
      default: return { EVL: null };
    }
  }
}

export {
  quickEval,
};
