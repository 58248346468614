import React from 'react';
import { connect } from 'react-redux';

import { pos2fen } from '../engine/tools';
import { move2pgn } from '../engine/pgn';

import './History.css';

import { setPosition } from '../store/positionStore';
import { setPointer, updateFen } from '../store/gameStore';

const mapStateToProps = (state) => state;

const History = (props) => {
  const { gameStore, dispatch } = props;
  // console.log(gameStore);
  const { history, pointer } = gameStore;
  if (!history) return null;
  let moveNum = history[0].position.w ? 0 : 1;
  moveNum += (history[0].id || 0);
  const list = history.map((item, index) => {
    if (item.id === 0) return null;
    if (item.position.last.length === 0) return null;
    // const moveNum = item.position.w ? Math.floor(item.id / 2) + 1 : Math.floor(item.id / 2 + 1);
    if (!item.position.w) moveNum += 1;
    return (
      <div
        key={item.id}
        className={`history-move-wrapper ${index === pointer ? 'pointer' : ''}`}
      >
        {(index === 1 && item.position.w) && (
          <span className="history-move-number">
            {`${item.id}...`}
          </span>
        )}
        <span
          className={!item.position.w ? 'history-move-number' : 'history-move-delimeter'}
        >
          {!item.position.w && `${moveNum}.`}
        </span>
        <span
          className={`history-move ${index === pointer ? 'pointer' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(setPosition(history[index].position));
            dispatch(setPointer(index));
            dispatch(updateFen(pos2fen(history[index].position)));
            // e.target.style.color = "gray";
          }}
          onKeyPress={() => false}
        >
          {move2pgn(history[index - 1].position, item.position.last) }
        </span>
      </div>
    );
  });

  return (
    <div
      className={`history-content ${pointer === 0 ? 'no-pointer' : ''}`}
      // onClick={(e) => {
      //   e.preventDefault();
      //   e.stopPropagation();
      //   dispatch(setPosition(history[history.length - 1].position));
      //   dispatch(setPointer(history.length - 1));
      //   dispatch(updateFen(pos2fen(history[history.length - 1].position)));
      // }}
      // onKeyPress={() => false}
    >
      {list}
    </div>
  );
};

export default connect(mapStateToProps)(History);
