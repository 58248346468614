/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { makeMove } from '../engine/moves';
import { quickEval } from '../engine/evaluate';
import { fen2pos } from '../engine/tools';

const TOUCH_PIECE = 'TOUCH_PIECE';
const SET_POSITION = 'SET_POSITION';
const INCORRECT_MOVE = 'INCORRECT_MOVE';
const ANIMATE_MOVE = 'ANIMATE_MOVE';
const PROMO_DIALOG = 'PROMO_DIALOG';
const MOVE_END = 'MOVE_END';
const EVALUATE = 'EVALUATE';
const RESET_POSITION = 'RESET_POSITION';
const RESET_GAME = 'RESET_GAME';
const INIT_GAME = 'INIT_GAME';

const setPosition = (newPosition) => ({
  type: SET_POSITION,
  payload: newPosition,
});

const resetPosition = (fen) => ({
  type: RESET_POSITION,
  payload: { fen },
});

const touchPiece = (touch) => ({
  type: TOUCH_PIECE,
  payload: { touch },
});

const incorrectMove = () => ({
  type: INCORRECT_MOVE,
  payload: {},
});

const animateMove = (touch, target) => ({
  type: ANIMATE_MOVE,
  payload: { touch, target },
});

const showPromoDialog = (touch, target) => ({
  type: PROMO_DIALOG,
  payload: { touch, target },
});

const moveEnd = (touch, target, promo) => ({
  type: MOVE_END,
  payload: { touch, target, promo },
});

const quickEvaluate = (message = {}) => ({
  type: EVALUATE,
  payload: message,
});

function setStage(state, stage, touch, target, newState = null) {
  try {
    // const ns = newState;
    switch (true) {
      // reset position
      // case stage === 0:
      //   return newState !== null
      //     ? newState : produce(state, (ns) => {
      //       ns.touch = null;
      //       ns.target = null;
      //       ns.stage = 0;
      //     });
      // touched piece
      case stage === 2:
        // console.log(listMoves(state, touch));
        return produce(state, (ns) => {
          ns.touch = touch;
          ns.target = null;
          ns.stage = 2;
        });
      // return to ready state
      case stage === 1:
        return newState !== null
          ? newState : produce(state, (ns) => {
            ns.touch = null;
            ns.target = null;
            ns.stage = 1;
            // ns.EVL = newState.EVL;
          });
      //   ns.target = null;
      //   ns.touch = null;
      //   ns.stage = 1;
      //   return ns;
      case stage === 6:
        return produce(state, (ns) => {
          ns.touch = null;
          ns.target = null;
          ns.stage = 6;
          ns.gameResult = newState.gameResult;
          ns.EVL = newState.EVL;
        });
      //   ns.stage = 6;
      //   return ns;
      case stage === 4:
        return produce(state, (ns) => {
          ns.touch = touch;
          ns.target = target;
          ns.stage = 4;
        });
      //   ns.target = target;
      //   ns.touch = touch;
      //   ns.stage = 4;
      //   return ns;
      case stage === 5:
        return produce(state, (ns) => {
          ns.touch = touch;
          ns.target = target;
          ns.stage = 5;
        });
      //   ns.target = target;
      //   ns.touch = touch;
      //   ns.stage = 5;
      //   return ns;
      default: return state;
    }
  } catch (err) {
    return err;
  }
}

const dispatcher = (state = {}, action) => {
  const { type, payload } = action;
  let ns;

  switch (type) {
    case RESET_GAME: return { ...payload, stage: 1 };
    case SET_POSITION: return { ...payload };
    case TOUCH_PIECE: return setStage(state, 2, payload.touch);
    case INCORRECT_MOVE: return setStage(state, 1, null, null);
    case RESET_POSITION: return setStage(state, 1, null, null, fen2pos(payload.fen));
    case ANIMATE_MOVE: return setStage(state, 4, payload.touch, payload.target);
    case PROMO_DIALOG: return setStage(state, 5, payload.touch, payload.target);
    case INIT_GAME: return { ...payload, stage: 1 };
    case EVALUATE: { // PLEASE CHECK THIS
      const result = quickEval(state);
      const { EVL, white, black } = result;
      switch (EVL) {
        case 'stalemate': return setStage(state, 6, null, null, { EVL, gameResult: { w: white, b: black } });
        case 'checkmate': return setStage(state, 6, null, null, { EVL, gameResult: { w: white, b: black } });
        case 'check': return { ...state, EVL };
        default: return { ...state, EVL: null }; // return setStage(state, 1, null, null);
      }
    }
    case MOVE_END:
      ns = makeMove(state, payload.touch, payload.target, payload.promo);
      // updateCurrentFen(pos2fen(ns));
      return setStage(state, 1, null, null, ns);
    default: return state;
  }
};

export default dispatcher;

export {
  touchPiece,
  incorrectMove,
  animateMove,
  showPromoDialog,
  moveEnd,
  quickEvaluate,
  resetPosition,
  setPosition,
};
