import { PIECES, SYMBOLS } from './constants';

function uci(square) {
  try {
    const file = 'abcdefgh'[(square % 10) - 1];
    const rank = 8 - Math.round((square - (square % 10)) / 10);
    return file + rank;
  } catch (err) {
    return err;
  }
}

function findPieces(position, piece) {
  try {
    const list = [];
    position.b.forEach((item, index) => {
      if (item === piece) list.push(index);
    });
    return list;
  } catch (err) {
    return err;
  }
}

function offBoard(position, square) {
  try {
    if (square < 1) return true;
    if (square > 78) return true;
    if (position.b[square] === null) return true;
    return false;
  } catch (err) {
    return err;
  }
}

function getPiece(position, square) {
  try {
    if (square < 0) return null;
    if (square > 79) return null;
    return position.b[square];
  } catch (err) {
    return err;
  }
}

const getPieceColor = (position, square) => {
  try {
    const piece = getPiece(position, square);
    switch (true) {
      case piece === 0 || piece === null: return null;
      case piece < 7: return 'w';
      default: return 'b';
    }
  } catch (err) {
    return err;
  }
};

const isOwnPiece = (position, square) => {
  try {
    switch (true) {
      case position.b[square] === 0 || position.b[square] === null: return null;
      case position.b[square] < 7 && position.w === true: return true;
      case position.b[square] > 6 && position.b[square] < 13 && position.w === false: return true;
      default: return false;
    }
  } catch (err) {
    return err;
  }
};

const isOpponent = (position, square) => {
  try {
    switch (true) {
      case position.b[square] === 0 || position.b[square] === null: return null;
      case position.b[square] < 7 && position.w === false: return true;
      case position.b[square] > 6 && position.b[square] < 13 && position.w === true: return true;
      default: return false;
    }
  } catch (err) {
    return err;
  }
};

const pos2fen = (pos) => {
  try {
    let i = 0;
    let j = 0;
    let spaces = 0;
    let fen = '';
    for (i = 0; i < 8; i += 1) {
      spaces = 0;
      for (j = 1; j < 9; j += 1) {
        switch (true) {
          case (pos.b[i * 10 + j] === 0): spaces += 1; break;
          default: fen += spaces || ''; fen += SYMBOLS[pos.b[i * 10 + j]]; spaces = 0; break;
        }
      }
      fen += spaces || '';
      if (i < 7) fen += '/';
    }
    fen += ' ';
    fen += pos.w ? 'w' : 'b';
    fen += ' ';
    if (pos.c[0]) fen += 'K';
    if (pos.c[1]) fen += 'Q';
    if (pos.c[2]) fen += 'k';
    if (pos.c[3]) fen += 'q';
    fen += pos.c[0] || pos.c[1] || pos.c[2] || pos.c[3] ? '' : '-';
    fen += ' ';
    const { e } = pos;
    fen += e === null ? '-' : 'abcdefgh'[(e % 10) - 1] + (8 - (e - (e % 10)) / 10);
    fen += ' ';
    fen += pos.m[0];
    fen += ' ';
    fen += pos.m[1];
    return fen;
  } catch (err) {
    return err;
  }
};

const fen2pos = (fen1 = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1') => {
  try {
    const fen = fen1.trim();
    let i = 0;
    let j = 1;
    const b = [null];
    let BK = null;
    let WK = null;
    let k;
    let piece;
    for (k = 0; fen[k] !== ' '; k += 1) {
      switch (true) {
        case ('rnbqkbnrpRNBQKBNRP'.indexOf(fen[k]) !== -1):
          piece = PIECES[fen[k]];
          b[i * 8 + j] = piece;
          if (piece === 12) BK = i * 8 + j;
          if (piece === 6) WK = i * 8 + j;
          j += 1;
          break;
        case (['1', '2', '3', '4', '5', '6', '7', '8'].indexOf(`${fen[k]}`) !== -1):
          for (let n = parseInt(fen[k], 10); n > 0; n -= 1) {
            b[i * 8 + j] = PIECES['-'];
            j += 1;
          }
          break;
        case (fen[k] === '/'):
          b[i * 8 + j] = null; j += 1; b[i * 8 + j] = null; j += 1;
          i += 1; i = 0; break;
        default: return false;
      }
    }
    b[79] = null;
    k += 1;
    const w = fen[k] === 'w';
    const c = [false, false, false, false];
    for (k += 2; fen[k] !== ' '; k += 1) {
      if (fen[k] === 'K') c[0] = true;
      else if (fen[k] === 'Q') c[1] = true;
      else if (fen[k] === 'k') c[2] = true;
      else if (fen[k] === 'q') c[3] = true;
      // else break;
    }
    k += 1;
    let e = [];
    switch (true) {
      case fen[k] === '-': e = null; break;
      case 'abcdefgh'.indexOf(fen[k]) !== -1: e = 'abcdefgh'.indexOf(fen[k]) + 1; k += 1; e += (8 - parseInt(fen[k], 10)) * 10; break;
      default: return false;
    }
    k += 2;
    const m = fen[k] ? fen.slice(k).split(' ').map((elem) => parseInt(elem, 10)) : [0, 1];
    return {
      b, w, c, e, m, touch: null, target: null, WK, BK, last: [],
    };
  } catch (err) {
    return err;
  }
};

const validateFen = (text) => {
  const fen = text.trim();
  const re = /\s*([rnbqkpRNBQKP1-8]+\/){7}([rnbqkpRNBQKP1-8]+)\s[bw-]\s(([a-hkqA-HKQ]{1,4})|(-))\s(([a-h][36])|(-))\s\d+\s\d+\s*/;
  if (re.test(fen) === false) return false;
  const x = fen.split(/\/|\s/);
  // if (x.length !== 8) return false;
  for (let i = 0; i < 8; i += 1) {
    let s = 0;
    for (let j = 0; j < x[i].length; j += 1) {
      const y = parseInt(x[i][j], 10) || 0;
      if (y > 0) {
        s += y;
        // j += 1;
        if (x[i][j + 1] && (parseInt(x[i][j + 1], 10) || 0) > 0) return false;
        // s += 1;
      } else s += 1;
    }
    if (s !== 8) return false;
  }
  return true;
};

export {
  pos2fen,
  fen2pos,
  getPieceColor,
  isOwnPiece,
  isOpponent,
  getPiece,
  offBoard,
  validateFen,
  uci,
  findPieces,
};
