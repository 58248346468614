import React from 'react';
import { PIECES } from '../engine/constants';

// images from here: https://commons.wikimedia.org/wiki/Template:SVG_chess_pieces#Dark_(black)_and_light_(white)

function calcTransform(touch, target, squareSize, flip) {
  try {
    const y = Math.round((touch - (touch % 10)) / 10) - Math.round((target - (target % 10)) / 10);
    const x = (touch % 10) - (target % 10);
    const sign = flip ? 1 : -1;
    return {
      transform: `translate(${sign * x * squareSize}px, ${sign * y * squareSize}px)`,
      transition: 'transform 300ms linear',
      zIndex: 10,
    };
  } catch (err) {
    return err;
  }
}

function Piece(props) {
  try {
    const {
      index, piece, squareSize,
      touch, target, stage,
      flip, hover, activeSide,

      finishPromotion,
    } = props;

    // styling transform for move animation
    const transformStyle = stage === 4 && index === touch
      ? calcTransform(touch, target, squareSize, flip) : {};

    const style = {
      width: `${squareSize}px`,
      height: `${squareSize}px`,
      display: 'block',
      position: 'absolute',
      zIndex: 1,
      ...transformStyle,
    };
    if (stage === 5 && index && index === touch) style.display = 'none';

    const props1 = {};

    if (hover) {
      props1.onMouseEnter = (e) => { e.target.style.transform = 'scale(1.05)'; };
      props1.onMouseLeave = (e) => { e.target.style.transform = 'scale(1)'; };
    }

    if (activeSide) {
      props1.draggable = true;
      props1.onMouseDown = (e) => { e.target.style.transform = 'scale(1.05)'; };
      props1.onMouseUp = (e) => { e.target.style.transform = 'scale(1)'; };
    }

    if (stage === 5) {
      props1.onMouseDown = () => { finishPromotion(PIECES[piece]); };
      props1.onTouchStart = () => { finishPromotion(PIECES[piece]); };
    }

    return (
      <img
        data-index={index}
        style={style}
        src={`images/${piece}45.svg`}
        alt=""
        draggable
        {...props1}
      />
    );
  } catch (err) {
    return null;
  }
}

export default Piece;
