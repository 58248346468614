import React from 'react';
import { connect } from 'react-redux';
// import { Transition, CSSTransition } from 'react-transition-group';
import Square from './Square';
import PromoDialog from './PromoDialog';
import Piece from './Piece';
import { isOwnPiece } from '../engine/tools';
import { SYMBOLS } from '../engine/constants';
import {
  handleClickSquare, handleDragStart, handleDragEnd, handleDrop, moveAnimationEnd,
  finishPromotion, handleTouch,
} from '../board-events';

const mapStateToProps = (state) => state;

function Board(props) {
  // console.log(props);
  const { positionStore, gameStore, dispatch } = props;
  const {
    b, touch, target, stage, w, last,
  } = positionStore;
  // console.log(`stage: ${stage}`);
  const {
    squareSize, flip, grid, animate,
  } = gameStore;
  // console.log(sendMessage);
  // useEffect(() => {
  //   if (gameStore.started === undefined) {
  //     const position = fen2pos(fen);
  //     dispatch(initGame(position));
  //     dispatch(quickEvaluate());
  //     if (position.w && gameStore.flip === true) dispatch(flipBoard());
  //     if (!position.w && gameStore.flip === false) dispatch(flipBoard());
  //   }
  // });
  if (b === undefined) return null;
  const mapSquares = (x, i) => (
    x !== null
    && (
      <Square
        key={i}
        index={i}
        squareSize={squareSize}
        activeSide={isOwnPiece(positionStore, i)}
        grid={grid}
        last={last && (last.s1 === i || last.s2 === i)}
      >
        <Piece
          piece={SYMBOLS[x]}
          activeSide={isOwnPiece(positionStore, i) && positionStore.stage !== 6}
          touch={touch}
          target={target}
          index={i}
          squareSize={squareSize}
          flip={flip}
          stage={stage}
        />
      </Square>
    )
  );

  const squaresArray = flip ? b.map(mapSquares).reverse() : b.map(mapSquares);

  const style = {
    display: 'grid',
    gridTemplateColumns: `repeat(8, ${squareSize}px)`,
    gridTemplateRows: `repeat(8, ${squareSize}px)`,
    zIndex: 1,
    position: 'relative',
  };

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={style}
        className="board"
        onClick={handleClickSquare(positionStore)(dispatch)(animate)}
        onDragStart={handleDragStart(positionStore)(dispatch)}
        onDragEnd={handleDragEnd(positionStore)(dispatch)}
        onDrop={handleDrop(positionStore)(dispatch)}
        onKeyPress={() => false}
        onTouchStart={handleTouch(positionStore)(dispatch)(animate)}
        onTouchEnd={handleTouch(positionStore)(dispatch)(animate)}
        onTransitionEnd={moveAnimationEnd(positionStore)(dispatch)}
      >
        {squaresArray}
      </div>
      {
        stage === 5
        && (
          <PromoDialog
            squareSize={squareSize}
            zIndex={2}
            col={target % 10}
            flip={flip}
            w={w}
            stage={stage}
            finishPromotion={finishPromotion(positionStore)(dispatch)}
          />
        )
      }
    </div>
  );
}

export default connect(mapStateToProps)(Board);
