import React from 'react';
import Board from './Board';
import Resizer from './Resizer';

function BoardFrame(props) {
  const {
    flip, squareSize, factorWidth,
  } = props;
  // const factorWidth = 'ontouchstart' in document.documentElement ? 0.3 : 0.3;
  const style = {
    display: 'grid',
    gridTemplateColumns: `${squareSize * 8}px ${squareSize * factorWidth}px`,
    gridTemplateRows: `${squareSize * 8}px ${squareSize * factorWidth}px`,
    position: 'relative',
    width: `${squareSize * (8 + factorWidth)}px`,
    boxShadow: '0 1px 2px 0 rgba(60,64,67,0.302),0 2px 6px 2px rgba(60,64,67,0.149)',
  };
  const styleList1 = {
    height: `${squareSize}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: `${squareSize * factorWidth * 0.7}px`,
    fontFamily: 'Arial',
  };
  const styleList2 = {
    width: `${squareSize}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: `${squareSize * factorWidth * 0.7}px`,
    fontFamily: 'Arial',
  };
  const styleList3 = {
    display: 'flex',
  };
  const list1 = [8, 7, 6, 5, 4, 3, 2, 1].map((x) => (
    <div key={x} style={styleList1}><div>{x}</div></div>
  ));
  const list2 = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'].map((x) => (
    <div key={x} style={styleList2}><div>{x}</div></div>
  ));
  if (flip) {
    list1.reverse();
    list2.reverse();
  }
  return (
    <div
      className="boardFrame"
      style={style}
      onKeyPress={() => false}
    >
      <Board />
      <div>{list1}</div>
      <div style={styleList3}>{list2}</div>
      <Resizer
        min={300}
      />
    </div>
  );
}

export default BoardFrame;
